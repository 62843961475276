'use strict';
$.noty.layouts.top = {
  name: 'top',
  options: {},
  container: {
    object: '<ul class="app-noty-top" />',
    selector: 'ul.app-noty-top',
    style: function() {}
  },
  parent: {
    object: '<li />',
    selector: 'li',
    css: {}
  },
  css: {
    display: 'none'
  },
  addClass: ''
};
$.noty.layouts.topLeft = {
  name: 'topLeft',
  options: {},
  container: {
    object: '<ul class="app-noty-top-left" />',
    selector: 'ul.app-noty-top-left',
    style: function() {}
  },
  parent: {
    object: '<li />',
    selector: 'li',
    css: {}
  },
  css: {
    display: 'none',
    width: '300px'
  },
  addClass: ''
};
$.noty.layouts.topRight = {
  name: 'topRight',
  options: {},
  container: {
    object: '<ul class="app-noty-top-right" />',
    selector: 'ul.app-noty-top-right',
    style: function() {}
  },
  parent: {
    object: '<li />',
    selector: 'li',
    css: {}
  },
  css: {
    display: 'none',
    width: '300px'
  },
  addClass: ''
};
//
$.noty.layouts.bottom = {
  name: 'bottom',
  options: {},
  container: {
    object: '<ul class="app-noty-bottom" />',
    selector: 'ul.app-noty-bottom',
    style: function() {}
  },
  parent: {
    object: '<li />',
    selector: 'li',
    css: {}
  },
  css: {
    display: 'none'
  },
  addClass: ''
};
$.noty.layouts.bottomLeft = {
  name: 'bottomLeft',
  options: {},
  container: {
    object: '<ul class="app-noty-bottom-left" />',
    selector: 'ul.app-noty-bottom-left',
    style: function() {}
  },
  parent: {
    object: '<li />',
    selector: 'li',
    css: {}
  },
  css: {
    display: 'none',
    width: '300px'
  },
  addClass: ''
};
$.noty.layouts.bottomRight = {
  name: 'bottomRight',
  options: {},
  container: {
    object: '<ul class="app-noty-bottom-right" />',
    selector: 'ul.app-noty-bottom-right',
    style: function() {}
  },
  parent: {
    object: '<li />',
    selector: 'li',
    css: {}
  },
  css: {
    display: 'none',
    width: '300px'
  },
  addClass: ''
};
